export const DEVICES = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile'
}

export const LAYOUT_SECTIONS = {
  TOP: 'Top',
  HEADER: 'Header',
  OVER_CONTENT: 'OverContent',
  LEFT_COLUMN: 'LeftColumn',
  RIGHT_COLUMN: 'RightColumn',
  BOTTOM: 'Bottom',
  OUTSIDE_CONTENT: 'OutsideContent'
}

export const WIDGETS = {
  COMMON: {
    LEADERBOARD: 'leaderboard',
    STRIP: 'strip',
    LOGO: 'logo',
    SEARCH: 'search',
    EMAIL_LINK: 'emailLink',
    RIGHT_BANNER: 'rightBanner',
    HALF_PAGE: 'halfPage',
    SMART_ADVERT_1: 'advertSmart1',
    SMART_ADVERT_2: 'advertSmart2',
    SMART_ADVERT_3: 'advertSmart3',
    SMART_ADVERT_4: 'advertSmart4'
  },
  HOMEPAGE: {
    HEADER_LOGIN: 'headerLogin',
    PROMO_WRAPPER: 'promoWrapper',
    DAILY_INFO_MOBILE: 'dailyInfoMobile',
    DAILY_INFO_DESKTOP: 'dailyInfoDesktop',
    DYNAMIC_LEAD: 'dynamicLead',
    NEWS: 'news',
    TIP_OF_THE_DAY: 'tipOfTheDay',
    SPORT: 'sport',
    WOMAN: 'woman',
    VIDEO: 'video',
    FINANCE: 'finance',
    CAR: 'car',
    REGIONAL: 'regional',
    NEWS_FEED: 'newsFeed',
    EMAIL: 'email',
    ECO_ID_LOGIN_PROMO: 'ecoIdLoginPromo',
    PROMO_LINK: 'promoLink',
    PROMO_VIDEO: 'promoVideo',
    SERVICES: 'services',
    WEATHER: 'weather',
    LEAFLETS: 'leaflets',
    EXCHANGE: 'exchange',
    TV_PROGRAM: 'tvProgram',
    GAMES: 'games',
    HOROSCOPES: 'horoscopes',
    NATIVE_APP_PROMO: 'nativeAppPromo',
    MOBILE_TABS: 'mobileTabs',
    MOBILE_DYNAMIC_LEAD: 'mobileDynamicLead',
    MOBILE_NEWS: 'mobileNews',
    MOBILE_SPORT: 'mobileSport',
    MOBILE_VIDEO: 'mobileVideo',
    MOBILE_FINANCE: 'mobileFinance',
    MOBILE_CAR: 'mobileCar',
    MOBILE_REGIONAL: 'mobileRegional',
    MOBILE_HOUSING: 'mobileHousing',
    MOBILE_WOMAN: 'mobileWoman',
    MOBILE_ICON_PROMO: 'mobileIconPromo',
    MOBILE_LEAFLETS: 'mobileLeaflets',
    BBX_INFO_WIDGET: 'bbxInfoWidget',
    CELEBRITIES: 'celebrities',
    MOBILE_CELEBRITIES: 'mobileCelebrities',
    VARENI: 'vareni',
    MOBILE_BYDLENI: 'mobileBydleni',
    BBX_ONLINE_SPORT: 'BbxOnlineSportWidget',
    MOBILE_USER_MENU: 'userMenu'
  },
  WEATHER: {
    NOTIFY: 'notify',
    WELCOME: 'welcome',
    VIDEO: 'video',
    FORECAST: 'forecast',
    SEASON: 'season',
    CITIES: 'cities',
    ALLERGY: 'allergy',
    WELCOME_DETAIL: 'welcomeDetail',
    SUNRISE: 'sunrise'
  },
  KECY: {
    VIDEO_PLAYER: 'kecyVideoPlayer'
  }
}

export const WIDGET_CONTAINERS = {
  BYDLENI_REALITY: 'bydleniReality'
}
