import React, { createRef } from 'react'
import LayoutUI from './LayoutUI'
import LayoutHeadless from './LayoutHeadless'
import {
  DailyInfoDesktop,
  DailyInfoMobile
} from '../components/widgets/DailyInfo'
import Dynamiclead from '../components/widgets/Dynamiclead'
import MobileDynamiclead from '../components/widgets/Dynamiclead/Mobile'
import Articles from '../components/widgets/Articles'
import MobileArticles from '../components/widgets/Articles/Mobile'
import TipOfTheDay from '../components/widgets/TipOfTheDay'
import Video from '../components/widgets/Video'
import MobileVideo from '../components/widgets/Video/Mobile'
import Finance from '../components/widgets/Finance'
import MobileFinance from '../components/widgets/Finance/Mobile'
import Regional from '../components/widgets/Regional'
import NewsFeed from '../components/widgets/NewsFeed'
import Email from '../components/widgets/Email'
import PromoLink from '../components/widgets/Tip/PromoLink'
import PromoVideo from '../components/widgets/Tip/PromoVideo'
import Services from '../components/widgets/Services'
import ForecastWeather from '../components/widgets/Weather/ForecastWeather'
import MobileWeather from '../components/widgets/Weather/MobileWeather'
import Exchange from '../components/widgets/Exchange'
import TvProgram from '../components/widgets/TvProgram'
import Games from '../components/widgets/Games'
import Horoscopes from '../components/widgets/Horoscopes'
import MobileHousing from '../components/widgets/Housing/Mobile'
import NativeAppPromo from '../components/widgets/NativeAppPromo'
import MobileTabs from '../components/widgets/MobileTabs'
import BbxInfoWidget from '../components/widgets/BbxInfoWidget'
import { LAYOUT_SECTIONS, WIDGETS } from '../constants'
import { EmailProvider } from '../context/Email'
import { HoroscopesProvider } from '../context/Horoscopes'
import { PrannotationProvider } from '../context/Prannotation'
import { SystemProvider } from '../context/System'
import MobileIconPromo from '../components/widgets/MobileIconPromo'
import MobileRegional from '../components/widgets/Regional/Mobile'
import Leaflets from '../components/widgets/Leaflets'
import MobileLeaflets from '../components/widgets/Leaflets/Mobile'
import Vareni from '../components/widgets/Vareni'
import MobileBydleni from '../components/widgets/Bydleni/Mobile'
import { WIDGET_CONTAINERS } from '../constants/layout'
import SelfPromo from '../components/widgets/SelfPromo/SelfPromo'
import BydleniRealityContainer
  from '../components/containers/BydleniRealityContainer'
import HotSearch from '../components/widgets/Header/HotSearch'
import OldSelfPromo from '../components/widgets/Header/OldSelfPromo'
import IdentitaEmail from '../components/widgets/Email/Identita'
import BbxOnlineSportWidget from '../components/widgets/BbxOnlineSportWidget'
import { MobileUserMenu } from './MobileUserMenu'
import {
  EcoIdLoginPromo
} from '../components/widgets/EcoIdLoginPromo/EcoIdLoginPromo'

class Homepage extends LayoutHeadless {
  constructor (props) {
    super(props)

    this.loginModalRef = createRef()
    this.userMenuRef = createRef()
  }

  componentDidMount () {
    super.componentDidMount()
    this.updateElements(this.loginModalRef, this.userMenuRef)
  }

  componentDidUpdate () {
    this.updateElements(this.loginModalRef, this.userMenuRef)
  }

  getDynamicWidgets () {
    const {
      props: {
        widgets,
        widgets: {
          config: {
            searchSecret,
            variant,
            ecoIdentity
          }
        }
      },
      state: {
        containers: {
          sashec
        },
        activeDynamicLayout
      }
    } = this

    const email = variant === 'EcoIdComplete'
      ? <IdentitaEmail widget={widgets.email} ecoIdentity={ecoIdentity} activeDynamicLayout={activeDynamicLayout} layout={activeDynamicLayout} />
      : <Email widget={widgets.email} variant={variant}/>
    const horoscopes = <Horoscopes widget={widgets.horoscopes}/>
    const tvProgram = <TvProgram widget={widgets.tvprogram} activeDynamicLayout={activeDynamicLayout}/>
    const mobileWeather = <MobileWeather widget={widgets.weather}/>

    const shouldShowSelfPromoWidget = widgets.selfpromo.boxes.length > 0
    const mobileUserMenu = <MobileUserMenu widget={widgets.usermenu} ecoIdentity={ecoIdentity} variant={variant} activeDynamicLayout={activeDynamicLayout}/>

    return Object.assign({}, super.getDynamicWidgets(), {
      [WIDGETS.HOMEPAGE.PROMO_WRAPPER]: () => shouldShowSelfPromoWidget
        ? (variant === 'EcoIdComplete' || variant === 'EcoIdTransition'
          ? <SelfPromo widget={widgets.selfpromo} layout={activeDynamicLayout}/>
          : <OldSelfPromo widget={widgets.selfpromo} layout={activeDynamicLayout}/>
        )
        : <HotSearch widget={widgets.hotsearch} secret={searchSecret} variant={variant}/>,
      [WIDGETS.HOMEPAGE.DAILY_INFO_MOBILE]: () => <DailyInfoMobile/>,
      [WIDGETS.HOMEPAGE.DAILY_INFO_DESKTOP]: () => <DailyInfoDesktop widgets={widgets}/>,
      [WIDGETS.HOMEPAGE.DYNAMIC_LEAD]: () => <Dynamiclead widget={widgets.dynamiclead}/>,
      [WIDGETS.HOMEPAGE.MOBILE_DYNAMIC_LEAD]: () => <MobileDynamiclead widget={widgets.dynamiclead}/>,
      [WIDGETS.HOMEPAGE.NEWS]: () => <Articles widget={widgets.news}/>,
      [WIDGETS.HOMEPAGE.MOBILE_NEWS]: () => <MobileArticles widget={widgets.news} isOpening={true}/>,
      [WIDGETS.HOMEPAGE.TIP_OF_THE_DAY]: () => <TipOfTheDay widget={widgets.tipoftheday}/>,
      [WIDGETS.HOMEPAGE.SPORT]: () => <Articles widget={widgets.sport}/>,
      [WIDGETS.HOMEPAGE.MOBILE_SPORT]: () => <MobileArticles widget={widgets.sport} isOpening={true}/>,
      [WIDGETS.HOMEPAGE.WOMAN]: () => <Articles widget={widgets.woman}/>,
      [WIDGETS.HOMEPAGE.MOBILE_WOMAN]: () => <MobileArticles widget={widgets.woman} isOpening={true}/>,
      [WIDGETS.HOMEPAGE.VIDEO]: () => <Video widget={widgets.video} activeDynamicLayout={activeDynamicLayout}/>,
      [WIDGETS.HOMEPAGE.MOBILE_VIDEO]: () => <MobileVideo widget={widgets.video} isOpening={true}/>,
      [WIDGETS.HOMEPAGE.FINANCE]: () => <Finance widget={widgets.finance} activeDynamicLayout={activeDynamicLayout}/>,
      [WIDGETS.HOMEPAGE.CAR]: () => <Articles widget={widgets.car}/>,
      [WIDGETS.HOMEPAGE.MOBILE_CAR]: () => <MobileArticles widget={widgets.car} isOpening={true}/>,
      [WIDGETS.HOMEPAGE.REGIONAL]: () => <Regional widget={widgets.regional} activeDynamicLayout={activeDynamicLayout}/>,
      [WIDGETS.HOMEPAGE.MOBILE_REGIONAL]: () => <MobileRegional widget={widgets.regional} activeDynamicLayout={activeDynamicLayout} isOpening={true}/>,
      [WIDGETS.HOMEPAGE.MOBILE_FINANCE]: () => <MobileFinance widget={widgets.finance} isOpening={true}/>,
      [WIDGETS.HOMEPAGE.NEWS_FEED]: () => <NewsFeed widget={widgets.newsfeed} widgets={widgets} containers={{ sashec }}
        activeDynamicLayout={activeDynamicLayout}/>,
      [WIDGETS.HOMEPAGE.EMAIL]: () => email,
      [WIDGETS.HOMEPAGE.ECO_ID_LOGIN_PROMO]: () => <EcoIdLoginPromo ecoIdentity={ecoIdentity} emailWidget={widgets.email} activeDynamicLayout={activeDynamicLayout}/>,
      [WIDGETS.HOMEPAGE.PROMO_LINK]: () => <PromoLink widget={widgets.promolink}/>,
      [WIDGETS.HOMEPAGE.PROMO_VIDEO]: () => <PromoVideo widget={widgets.promovideo}/>,
      [WIDGETS.HOMEPAGE.SERVICES]: () => <Services widget={widgets.services}/>,
      [WIDGETS.HOMEPAGE.WEATHER]: () => <ForecastWeather widget={widgets.weather}/>,
      [WIDGETS.HOMEPAGE.LEAFLETS]: () => <Leaflets widget={widgets.leaflets}/>,
      [WIDGETS.HOMEPAGE.MOBILE_LEAFLETS]: () => <MobileLeaflets widget={widgets.leaflets} activeDynamicLayout={activeDynamicLayout} isOpening={true}/>,
      [WIDGETS.HOMEPAGE.EXCHANGE]: () => <Exchange widget={widgets.exchange} isOpening={true}/>,
      [WIDGETS.HOMEPAGE.TV_PROGRAM]: () => tvProgram,
      [WIDGETS.HOMEPAGE.GAMES]: () => <Games widget={widgets.games}/>,
      [WIDGETS.HOMEPAGE.HOROSCOPES]: () => horoscopes,
      [WIDGETS.HOMEPAGE.MOBILE_HOUSING]: () => <MobileHousing widget={widgets.housing} activeDynamicLayout={activeDynamicLayout} isOpening={true}/>,
      [WIDGETS.HOMEPAGE.NATIVE_APP_PROMO]: () => <NativeAppPromo widget={widgets.nativeapppromo}/>,
      [WIDGETS.HOMEPAGE.MOBILE_ICON_PROMO]: () => <MobileIconPromo widget={widgets.mobileiconpromo}/>,
      [WIDGETS.HOMEPAGE.CELEBRITIES]: () => <Articles widget={widgets.celebrities}/>,
      [WIDGETS.HOMEPAGE.MOBILE_CELEBRITIES]: () => <MobileArticles widget={widgets.celebrities} isOpening={true}/>,
      [WIDGETS.HOMEPAGE.VARENI]: () => <Vareni widget={widgets.vareni}/>,
      [WIDGETS.HOMEPAGE.MOBILE_BYDLENI]: () => <MobileBydleni widget={widgets.bydleni} isOpening={true}/>,
      [WIDGETS.HOMEPAGE.MOBILE_TABS]: () => (
        <MobileTabs widget={widgets.mobiletabs} ecoIdentity={ecoIdentity} variant={variant}>
          {email}
          {horoscopes}
          {tvProgram}
          {mobileWeather}
          {mobileUserMenu}
        </MobileTabs>
      ),
      [WIDGETS.HOMEPAGE.BBX_INFO_WIDGET]: () => <BbxInfoWidget widget={widgets.bbxInfoWidget}/>,
      [WIDGET_CONTAINERS.BYDLENI_REALITY]: () => <BydleniRealityContainer bydleniWidget={widgets.bydleni} housingWidget={widgets.housing}/>,
      [WIDGETS.HOMEPAGE.BBX_ONLINE_SPORT]: () => <BbxOnlineSportWidget widget={widgets.onlinesport} activeDynamicLayout={activeDynamicLayout} />
    })
  }

  getDesktopWidgetsLayout () {
    const variant = this.props.widgets.config.variant

    return {
      [LAYOUT_SECTIONS.TOP]: [
        WIDGETS.COMMON.LEADERBOARD
      ],
      [LAYOUT_SECTIONS.HEADER]: [
        WIDGETS.COMMON.LOGO,
        WIDGETS.COMMON.SEARCH,
        ...(variant === 'EcoIdComplete' || variant === 'EcoIdTransition'
          ? [WIDGETS.HOMEPAGE.HEADER_LOGIN]
          : [WIDGETS.HOMEPAGE.PROMO_WRAPPER]
        )
      ],
      [LAYOUT_SECTIONS.OVER_CONTENT]: [
        WIDGETS.HOMEPAGE.DAILY_INFO_DESKTOP
      ],
      [LAYOUT_SECTIONS.LEFT_COLUMN]: [
        WIDGETS.HOMEPAGE.BBX_INFO_WIDGET,
        WIDGETS.HOMEPAGE.DYNAMIC_LEAD,
        WIDGETS.HOMEPAGE.NEWS,
        WIDGETS.HOMEPAGE.TIP_OF_THE_DAY,
        WIDGETS.HOMEPAGE.VIDEO,
        WIDGETS.HOMEPAGE.WOMAN,
        WIDGETS.HOMEPAGE.CELEBRITIES,
        WIDGETS.HOMEPAGE.SPORT,
        WIDGET_CONTAINERS.BYDLENI_REALITY,
        WIDGETS.HOMEPAGE.FINANCE,
        WIDGETS.HOMEPAGE.CAR,
        WIDGETS.HOMEPAGE.REGIONAL,
        WIDGETS.HOMEPAGE.NEWS_FEED
      ],
      [LAYOUT_SECTIONS.RIGHT_COLUMN]: [
        ...(variant === 'EcoIdComplete' || variant === 'EcoIdTransition'
          ? [WIDGETS.HOMEPAGE.PROMO_WRAPPER]
          : []),
        ...(variant === 'EcoIdTransition'
          ? [WIDGETS.HOMEPAGE.ECO_ID_LOGIN_PROMO]
          : []),
        WIDGETS.HOMEPAGE.EMAIL,
        WIDGETS.HOMEPAGE.PROMO_VIDEO,
        WIDGETS.HOMEPAGE.SERVICES,
        WIDGETS.COMMON.RIGHT_BANNER,
        WIDGETS.HOMEPAGE.WEATHER,
        WIDGETS.HOMEPAGE.BBX_ONLINE_SPORT,
        WIDGETS.HOMEPAGE.VARENI,
        WIDGETS.HOMEPAGE.LEAFLETS,
        WIDGETS.HOMEPAGE.EXCHANGE,
        WIDGETS.HOMEPAGE.TV_PROGRAM,
        WIDGETS.HOMEPAGE.GAMES,
        WIDGETS.HOMEPAGE.HOROSCOPES,
        WIDGETS.COMMON.HALF_PAGE
      ],
      [LAYOUT_SECTIONS.BOTTOM]: [],
      [LAYOUT_SECTIONS.OUTSIDE_CONTENT]: []
    }
  }

  getTabletWidgetsLayout () {
    const {
      widgets: {
        config: {
          variant
        }
      }
    } = this.props

    return {
      [LAYOUT_SECTIONS.TOP]: [
        WIDGETS.COMMON.STRIP
      ],
      [LAYOUT_SECTIONS.HEADER]: [
        WIDGETS.COMMON.LOGO,
        WIDGETS.COMMON.SEARCH
      ],
      [LAYOUT_SECTIONS.OVER_CONTENT]: [],
      [LAYOUT_SECTIONS.LEFT_COLUMN]: [
        ...(variant === 'EcoIdComplete' || variant === 'EcoIdTransition'
          ? [WIDGETS.HOMEPAGE.MOBILE_TABS]
          : [WIDGETS.HOMEPAGE.EMAIL]),
        WIDGETS.HOMEPAGE.BBX_INFO_WIDGET,
        WIDGETS.HOMEPAGE.PROMO_LINK,
        WIDGETS.HOMEPAGE.PROMO_WRAPPER,
        WIDGETS.HOMEPAGE.DAILY_INFO_DESKTOP,
        WIDGETS.HOMEPAGE.DYNAMIC_LEAD,
        WIDGETS.COMMON.SMART_ADVERT_1,
        WIDGETS.HOMEPAGE.NEWS,
        WIDGETS.HOMEPAGE.VARENI,
        WIDGETS.HOMEPAGE.LEAFLETS,
        WIDGETS.HOMEPAGE.VIDEO,
        WIDGETS.HOMEPAGE.WOMAN,
        WIDGETS.COMMON.SMART_ADVERT_2,
        WIDGETS.HOMEPAGE.CELEBRITIES,
        WIDGETS.HOMEPAGE.SPORT,
        WIDGETS.HOMEPAGE.FINANCE,
        WIDGETS.HOMEPAGE.CAR,
        WIDGETS.HOMEPAGE.REGIONAL,
        WIDGETS.HOMEPAGE.WEATHER,
        WIDGETS.HOMEPAGE.BBX_ONLINE_SPORT,
        WIDGETS.HOMEPAGE.EXCHANGE,
        WIDGET_CONTAINERS.BYDLENI_REALITY,
        WIDGETS.HOMEPAGE.TV_PROGRAM,
        WIDGETS.COMMON.SMART_ADVERT_3,
        WIDGETS.HOMEPAGE.NEWS_FEED
      ],
      [LAYOUT_SECTIONS.RIGHT_COLUMN]: [],
      [LAYOUT_SECTIONS.BOTTOM]: [],
      [LAYOUT_SECTIONS.OUTSIDE_CONTENT]: []
    }
  }

  getMobileWidgetsLayout () {
    return {
      [LAYOUT_SECTIONS.TOP]: [
        WIDGETS.COMMON.STRIP
      ],
      [LAYOUT_SECTIONS.HEADER]: [
        WIDGETS.COMMON.LOGO,
        WIDGETS.COMMON.SEARCH
      ],
      [LAYOUT_SECTIONS.OVER_CONTENT]: [],
      [LAYOUT_SECTIONS.LEFT_COLUMN]: [
        WIDGETS.HOMEPAGE.DAILY_INFO_MOBILE,
        WIDGETS.HOMEPAGE.MOBILE_TABS,
        WIDGETS.HOMEPAGE.PROMO_WRAPPER,
        WIDGETS.HOMEPAGE.PROMO_LINK,
        WIDGETS.HOMEPAGE.BBX_INFO_WIDGET,
        WIDGETS.HOMEPAGE.MOBILE_DYNAMIC_LEAD,
        WIDGETS.COMMON.SMART_ADVERT_1,
        WIDGETS.HOMEPAGE.MOBILE_NEWS,
        WIDGETS.HOMEPAGE.MOBILE_VIDEO,
        WIDGETS.HOMEPAGE.MOBILE_WOMAN,
        WIDGETS.COMMON.SMART_ADVERT_2,
        WIDGETS.HOMEPAGE.MOBILE_CELEBRITIES,
        WIDGETS.HOMEPAGE.MOBILE_SPORT,
        WIDGETS.HOMEPAGE.BBX_ONLINE_SPORT,
        WIDGETS.HOMEPAGE.EXCHANGE,
        WIDGETS.HOMEPAGE.MOBILE_FINANCE,
        WIDGETS.HOMEPAGE.MOBILE_CAR,
        WIDGETS.HOMEPAGE.MOBILE_REGIONAL,
        WIDGETS.HOMEPAGE.MOBILE_BYDLENI,
        WIDGETS.HOMEPAGE.MOBILE_HOUSING,
        WIDGETS.HOMEPAGE.VARENI,
        WIDGETS.HOMEPAGE.MOBILE_LEAFLETS,
        WIDGETS.COMMON.SMART_ADVERT_3,
        WIDGETS.HOMEPAGE.NEWS_FEED
      ],
      [LAYOUT_SECTIONS.RIGHT_COLUMN]: [],
      [LAYOUT_SECTIONS.BOTTOM]: [],
      [LAYOUT_SECTIONS.OUTSIDE_CONTENT]: [
        WIDGETS.COMMON.SMART_ADVERT_4
      ]
    }
  }

  render () {
    const {
      layout,
      widgets,
      widgets: {
        config: {
          proxyOptions,
          variant,
          cookies: cookieNameConstants,
          browserCookies,
          ecoIdentity: {
            userInfo: {
              reloadIntervalMs
            }
          }
        },
        layout: {
          meta: {
            inbox: {
              serviceUrlPattern,
              xhrLogoutUri,
              isTracked
            }
          }
        }
      }
    } = this.props

    const emailOptions = {
      proxyOptions,
      serviceUrlPattern,
      xhrLogoutUri,
      isTracked,
      variant,
      cookieNameConstants,
      cookies: browserCookies,
      ecoIdentityReloadIntervalMs: reloadIntervalMs
    }

    return (
      <SystemProvider>
        <PrannotationProvider>
          <EmailProvider options={emailOptions}>
            <HoroscopesProvider options={widgets.horoscopes}>
              <LayoutUI
                layout={layout}
                widgets={widgets}
                top={this.renderTop()}
                header={this.renderHeader()}
                overContent={this.renderOverContent()}
                leftColumn={this.renderLeftColumn()}
                rightColumn={this.renderRightColumn()}
                bottom={this.renderBottom()}
                outsideContent={this.renderOutsideContent()}
                googleAnalytics={this.googleAnalytics}
                loginModalRef={this.loginModalRef}
                userMenuRef={this.userMenuRef}
                onHeaderHeightChange={this.onHeaderHeightChange}
              />
            </HoroscopesProvider>
          </EmailProvider>
        </PrannotationProvider>
      </SystemProvider>
    )
  }
}

export default Homepage
